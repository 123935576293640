import { db, functions } from "@/firebase";
import router from "@/router";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    classifications: [],
    document: {},
    documents: [],
    file: null,
    status: {},
    templates: [],
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async cancelSignatory({ commit }, data) {
    try {
      commit("wait");
      const cancelSignatory = httpsCallable(functions, "cancelSignatory");
      const res = await cancelSignatory(data);

      if (!res.data.success) throw "unknown";

      commit("setDocument", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async cancelSigning({ commit }, documentId) {
    try {
      commit("wait");
      const cancelSigning = httpsCallable(functions, "cancelSigning");
      const res = await cancelSigning({ documentId });

      if (!res.data.success) throw "unknown";

      commit("setDocument", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async createDocument({ commit }, data) {
    try {
      commit("wait");
      const createDocument = httpsCallable(functions, "createDocument");
      const res = await createDocument(data);

      if (!res.data.success) throw "unknown";

      commit("addDocument", res.data.data);
      router.push(`/documents/${res.data.data.id}`);
    } catch (error) {
      commit("failure", error);
    }
  },
  async createTemplate({ commit }, data) {
    try {
      commit("wait");
      const createTemplate = httpsCallable(functions, "createTemplate");
      const res = await createTemplate(data);

      if (!res.data.success) throw "unknown";

      commit("addTemplate", res.data.data);
      router.push(`/documents/${res.data.data.id}`);
    } catch (error) {
      commit("failure", error);
    }
  },
  async generatePDF({ commit }, id) {
    try {
      commit("wait");
      const getDocumentFile = httpsCallable(functions, "getDocumentFile");
      const res = await getDocumentFile({ documentId: id });

      commit("setFile", res.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getClassifications({ commit }) {
    try {
      commit("wait");
      const querySnapshot = await getDocs(collection(db, "classifications"));
      const classifications = querySnapshot.docs.map((doc) => {
        const classification = doc.data();
        classification.id = doc.id;
        return classification;
      });
      commit("setClassifications", classifications);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getDocument({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "documents", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const document = docSnap.data();
        document.id = docSnap.id;
        commit("setDocument", document);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getDocuments({ commit }, companyId) {
    try {
      commit("wait");
      const getDocuments = httpsCallable(functions, "getCompanyDocuments");
      const res = await getDocuments({ companyId });

      if (!res.data.success) throw "unknown";

      commit("setDocuments", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getEstateDocuments({ commit }, estateId) {
    try {
      commit("wait");
      const q = query(
        collection(db, "documents"),
        where("estateId", "==", estateId)
      );
      const querySnapshot = await getDocs(q);
      const documents = querySnapshot.docs.map((doc) => {
        const document = doc.data();
        document.id = doc.id;
        return document;
      });

      commit("setDocuments", documents);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getTemplates({ commit }, companyId) {
    try {
      commit("wait");
      const getTemplates = httpsCallable(functions, "getCompanyTemplates");
      const res = await getTemplates({ companyId });

      if (!res.data.success) throw "unknown";

      commit("setTemplates", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async sendForSigning({ commit }, data) {
    try {
      commit("wait");
      const sendForSigning = httpsCallable(functions, "sendForSigning");
      const res = await sendForSigning(data);

      if (!res.data.success) throw "unknown";

      commit("replaceDocument", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateDocument({ commit }, data) {
    try {
      commit("wait");
      const updateDocument = httpsCallable(functions, "updateDocument");
      const res = await updateDocument(data);

      if (!res.data.success) throw "unknown";

      commit("replaceDocument", res.data.data);
      router.push(`/documents/${res.data.data.id}`);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateTemplate({ commit }, data) {
    try {
      commit("wait");
      const updateTemplate = httpsCallable(functions, "updateDocument");
      const res = await updateTemplate(data);

      if (!res.data.success) throw "unknown";

      commit("replaceTemplate", res.data.data);
      router.push(`/documents/${res.data.data.id}`);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  addDocument(state, document) {
    state.document = document;
    state.documents.push(document);
    state.waiting = false;
  },
  addTemplate(state, template) {
    state.document = template;
    state.templates.push(template);
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  replaceDocument(state, document) {
    const index = state.documents.findIndex((d) => d.id == document.id);

    if (index != -1) {
      state.documents.splice(index, 1, document);
    }
    state.document = document;
    state.waiting = false;
  },
  replaceTemplate(state, template) {
    const index = state.templates.findIndex((t) => t.id == template.id);

    if (index != -1) {
      state.templates.splice(index, 1, template);
    }
    state.document = template;
    state.waiting = false;
  },
  setClassifications(state, classifications) {
    state.classifications = classifications;
    state.waiting = false;
  },
  setDocument(state, document) {
    state.document = document;
    state.waiting = false;
  },
  setDocuments(state, documents) {
    state.documents = documents;
    state.waiting = false;
  },
  setFile(state, file) {
    state.file = file;
    state.waiting = false;
  },
  setTemplates(state, templates) {
    state.templates = templates;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getDocument(state) {
    return state.document;
  },
};

const document = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default document;

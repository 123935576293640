import { db, functions } from "@/firebase";
import router from "@/router";
import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    notification: {},
    notifications: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async complete({ commit }, id) {
    try {
      commit("wait");
      const completeAlteration = httpsCallable(functions, "completeAlteration");
      const res = await completeAlteration({ id });

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "alteration_completed");
      commit("setNotification", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async createNotification({ commit }, data) {
    try {
      commit("wait");
      const createNotification = httpsCallable(functions, "createNotification");
      const res = await createNotification(data);

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "notification_created");
      commit("addNotification", res.data.data);
      router.push("/notifications");
    } catch (error) {
      commit("failure", error);
    }
  },
  async getLatestNotifications({ commit }, companyId) {
    try {
      commit("wait");
      const getLatestNotifications = httpsCallable(
        functions,
        "getLatestCompanyNotifications"
      );
      const res = await getLatestNotifications({ companyId });

      if (!res.data.success) throw "unknown";

      commit("setNotifications", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getNotification({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "notifications", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const notification = docSnap.data();
        notification.id = docSnap.id;
        commit("setNotification", notification);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getNotifications({ commit }, companyId) {
    try {
      commit("wait");
      const getNotifications = httpsCallable(
        functions,
        "getCompanyNotifications"
      );
      const res = await getNotifications({ companyId });

      if (!res.data.success) throw "unknown";

      commit("setNotifications", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  addNotification(state, notification) {
    state.notifications.push(notification);
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  } /*
  replaceNotification(state, notification) {
    const index = state.notifications.findIndex((b) => b.id == notification.id);
    if (index != -1) {
      state.notifications.splice(index, 1, notification);
    }
    state.notification = notification;
    state.waiting = false;
  },*/,
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setNotification(state, notification) {
    state.notification = notification;
    state.waiting = false;
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getNotification(state) {
    return state.notification;
  },
};

const notification = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default notification;
